import { useEffect, useState } from 'react';
import formItemProps, { areScheduleDaysValid, isValidScheduleDay } from './formItemProps';
import { Input, Form, Select, DatePicker, Checkbox, TimePicker, InputNumber } from 'antd';
import Button from '../UI/Button/Button';
import { ALL_CURRENCIES, CUSTOMER_TYPE } from '../../constants';
import Icon from '../UI/Icon/Icon';
import dayjs from 'dayjs';
import ModalButtonsContainer from '../UI/ModalButtonsContainer/ModalButtonsContainer';
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { daysOfWeek } from '../../constants/timeConstants';
import { classNames, firstLetterToUpperCase } from '../../shared/utility';
import { returnClassesDetails } from '../../shared/classFunctions';
import { returnAvailableClassTypesInGym } from '../../shared/gymFunctions';
import Upload from '../../containers/ManageVideos/VideosList/AddEditVideo/Upload';
import VideoConfig from '../../containers/ManageVideos/VideoConfig/VideoConfig';
import VideoPicker from '../../containers/ManageVideos/VideoPicker/VideoPicker';
import { isValueCorrect } from '../../shared/userFunctions';
import ShowTrainersShifts from '../../containers/ManageStaffShifts/ShowTrainersShifts/ShowTrainersShifts';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

/**    ALWAYS REMEMBER TO  PASS CHECKBOX INITIAL VALUE !          */

const RenderForm = ({
  autoComplete = 'off',
  formFields = [],
  excludeFormFields = [],
  onFinish = () => {},
  onFinishFailed = () => {},
  initialValues = {},
  btnLabel = 'save',
  btnIsLoading = false,
  className = '',
  gymsList = [],
  templatesList = [],
  trainersList = [],
  usersList = [],
  cancelBtn = false,
  onCancel = () => {},
  availableClassTypes = [],
  classType = 'class',
  clearFormOnFinish = false,
  gymSettings = undefined,
  productsList = [],
  videoCategoriesList = { active: [], inactive: [] },
  videos = [],
  showStaffShifts = false
}) => {
  const [updatedFormValues, setUpdatedFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const allFormFields = formFields.filter((elm) => !excludeFormFields.includes(elm));
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const handleOnFinish = (data) => {
    data = { ...data, ...updatedFormValues };
    if (allFormFields.includes('selectGymsAccess') && data.gymsFullAccess) {
      data.gymId = [];
    }
    if (allFormFields.includes('shifts')) {
      data = {
        ...data,
        shifts: data.shifts.map((elm) => ({
          ...elm,
          startingFrom: dayjs(elm.startingFrom).format('HH:mm'),
          endsAt: dayjs(elm.endsAt).format('HH:mm')
        }))
      };
    }
    if (allFormFields.includes('classDate')) {
      data = { ...data, classDate: dayjs(data.classDate).format('YYYY-MM-DD') };
    }
    if (allFormFields.includes('expiryDate') && isValueCorrect(data.expiryDate)) {
      data = { ...data, expiryDate: dayjs(data.expiryDate).format('YYYY-MM-DD') };
    }
    if (allFormFields.includes('dateofbirth')) {
      data = { ...data, dateofbirth: data.dateofbirth.format('YYYY-MM-DD') };
    }
    if (allFormFields.includes('classTime')) {
      data = { ...data, classTime: dayjs(data.classTime).format('h:mm A') };
    }
    if (allFormFields.includes('scheduledDaysOfTheWeek')) {
      data = { ...data, recurrentOn: daysOfWeek.reduce((a, v) => ({ ...a, [v.index]: data[v.value] }), {}) };
    }
    if (allFormFields.includes('template')) {
      data = {
        ...data,
        stripeProductId: templatesList.find((elm) => elm._id === data.templateId).stripeProductId,
        classTemplateId: data.templateId
      };
    }

    if (allFormFields.includes('shifts')) {
      data = {
        ...data,
        shifts: data.shifts.map(({ day, startingFrom, endsAt }) => ({ day, startingFrom, endsAt }))
      };
    }
    if (allFormFields.includes('selectPTClients')) {
      const attendanceList =
        data.additionalClientsId?.length > 0
          ? [data.selectedClientId, ...data.additionalClientsId]
          : [data.selectedClientId];
      data = {
        ...data,
        places: attendanceList.length,
        attendanceList
      };
    }

    onFinish(data);
    if (clearFormOnFinish) {
      form.resetFields();
    }
  };

  const onFieldChange = (name, value) => {
    let newFormValues = { ...updatedFormValues, ...form.getFieldsValue() };

    if (name === 'templateId') {
      const template = templatesList.find((elm) => elm._id === value);
      if (template !== -1) {
        newFormValues = {
          ...newFormValues,
          ...template,
          description: template.defaultDescription,
          cost: template.defaultCost,
          limit: template.defaultLimit,
          duration: template.defaultDuration,
          minimalTimeCancellationInHours: template.defaultMinimalTimeCancellationInHours,
          stripeProductId: template.stripeProductId
        };
        delete newFormValues.classType;
        delete newFormValues._id;
      }
    } else {
      newFormValues = name !== '' ? { ...newFormValues, [name]: value } : { ...newFormValues };
    }

    setUpdatedFormValues(newFormValues);
    form.setFieldsValue(newFormValues);
  };
  const returnFormField = (formItemPropsName) => {
    const returnItem = (name, field) => {
      switch (name) {
        case 'currency':
          return <Select options={ALL_CURRENCIES} placeholder={field.placeholder} />;
        case 'title':
          return (
            <Select
              options={[
                { value: 'Mr', label: 'Mr' },
                { value: 'Mrs', label: 'Mrs' },
                { value: 'Miss', label: 'Ms' },
                { value: 'Ms', label: 'Ms' },
                { value: 'Dr', label: 'Dr' }
              ]}
              rules={[
                {
                  required: true,
                  message: 'Please select title!'
                }
              ]}
              placeholder={field.placeholder}
            />
          );
        case 'classDate':
        case 'expiryDate':
          return (
            <DatePicker
              allowClear={false}
              className={'w-full'}
              defaultValue={initialValues?.[name]}
              format={'YYYY-MM-DD'}
              disabledDate={(current) =>
                field?.onlyPast
                  ? current && current > dayjs().endOf('day')
                  : field?.onlyFuture
                    ? current && current < dayjs().endOf('day')
                    : null
              }
              placeholder={field.placeholder}
            />
          );
        case 'classTime':
          return (
            <TimePicker
              defaultValue={initialValues?.[name]}
              allowClear={false}
              className={'w-full'}
              onSelect={(e) => onFieldChange('classTime', e)}
              format={'HH:mm'}
              placeholder={field.placeholder}
            />
          );
        case 'password':
        case 'confirmPassword':
          return <Input.Password placeholder={field.placeholder} />;

        case 'selectGym':
          return (
            <Select
              placeholder="Select gym"
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              options={gymsList.map((elm) => ({ value: elm._id, label: elm.name, key: elm._id }))}
            />
          );
        case 'template':
          return (
            <Select
              onChange={(templateId) => onFieldChange(field.name, templateId)}
              placeholder={'Select template'}
              options={templatesList
                .filter((elm) => elm.active === true)
                .map((elm) => ({ value: elm._id, label: elm.name, key: elm._id }))}
            />
          );
        case 'customerType':
          return <Select options={CUSTOMER_TYPE} />;

        case 'selectClassType':
        case 'forClass':
          return (
            <Select
              placeholder={field.placeholder}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              options={returnAvailableClassTypesInGym(gymSettings).map(({ name, title, type }) => ({
                value: type,
                label: title,
                key: name
              }))}
            />
          );
        case 'selectProducts':
          return (
            <Select
              mode={'multiple'}
              placeholder={field.placeholder}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              options={productsList.map(({ name, _id }) => ({ value: _id, label: name, key: _id }))}
            />
          );
        case 'selectQuestionType':
          return (
            <Select
              placeholder={field.placeholder}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              options={['general', 'non customer', ...availableClassTypes.map((elem) => elem.name)].map((elm, i) => ({
                value: elm,
                label: firstLetterToUpperCase(elm),
                key: i
              }))}
            />
          );
        case 'paymentNotes':
        case 'generalNotes':
        case 'description':
        case 'defaultDescription':
        case 'explainer':
        case 'question':
        case 'answer':
          return <Input.TextArea rows={3} placeholder={field.placeholder} />;
        case 'defaultLimit':
        case 'limit':
        case 'duration':
        case 'defaultDuration':
        case 'minimalTimeCancellationInHours':
        case 'defaultMinimalTimeCancellationInHours':
        case 'classCount':
        case 'displayOrder':
        case 'minimalUserAge':
        case 'minContractLength':
        case 'cost':
        case 'defaultCost':
        case 'membershipClassPrice':
        case 'price':
        case 'classPrice':
          return (
            <InputNumber className="w-full" min={field.minValue ? field.minValue : 0} placeholder={field.placeholder} />
          );
        case 'private':
        case 'enabled':
        case 'active':
        case 'oneOff':
        case 'enabledForScheduling':
        case 'qrCode':
        case 'membershipProducts':
        case 'newClientsHaveToBeApprovedByAdmin':
        case 'videoOnDemand':
        case 'liveStreaming':
        case 'coupon':
        case 'staffShifts':
        case 'faq':
        case 'kitList':
        case 'joiningFee':
        case 'unlimitedClasses':
        case 'marketing':
        case 'rememberMe':
        case 'sendClassChangeNotification':
        case 'enforceVideoOrder':
          return <Checkbox>{formItemProps[formItemPropsName].checkboxLabel}</Checkbox>;

        default:
          return <Input placeholder={field.placeholder} />;
      }
    };

    switch (formItemPropsName) {
      case 'trainer':
      case 'selectTrainer':
        return (
          <>
            <Form.Item
              {...formItemProps[formItemPropsName]}
              rules={[
                {
                  required: true,
                  message: 'Please select trainer!'
                }
              ]}>
              <Select
                placeholder={formItemProps[formItemPropsName].placeholder}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                options={trainersList.map(({ _id, name, last_name }) => ({
                  value: _id,
                  label: name + ' ' + last_name,
                  key: _id
                }))}
              />
            </Form.Item>
            {showStaffShifts && (
              <ShowTrainersShifts
                validate={false}
                shifts={
                  isValueCorrect(updatedFormValues[formItemProps[formItemPropsName].name])
                    ? trainersList.find((elm) => elm._id === updatedFormValues[formItemProps[formItemPropsName].name])
                        .shifts
                    : []
                }
                type={'class'}
                data={updatedFormValues}
                setIsStaffShiftsValid={(isValid) =>
                  form.setFields([
                    {
                      name: formItemProps[formItemPropsName].name,
                      errors: isValid ? [] : ['Trainer is not available at that time!']
                    }
                  ])
                }
              />
            )}
          </>
        );
      case 'selectVideos':
        return (
          <Form.Item
            label={'Videos'}
            name={'videos'}
            rules={[
              {
                validator: () => {
                  if (
                    updatedFormValues[formItemProps[formItemPropsName].name] !== undefined &&
                    updatedFormValues[formItemProps[formItemPropsName].name].length > 0
                  ) {
                    setFormErrors((state) => ({ ...state, [formItemProps[formItemPropsName].name]: false }));
                    return Promise.resolve();
                  } else {
                    setFormErrors((state) => ({ ...state, [formItemProps[formItemPropsName].name]: true }));
                    return Promise.reject('err');
                  }
                },
                message: 'Please select videos!'
              }
            ]}
            key={formItemPropsName}>
            <div
              className={classNames(
                formErrors && formErrors[formItemProps[formItemPropsName].name] && 'border-custom-red border-[1px]'
              )}>
              <VideoPicker
                videos={videos}
                initialSelection={
                  isValueCorrect(initialValues[formItemProps[formItemPropsName].name])
                    ? initialValues[formItemProps[formItemPropsName].name]
                    : []
                }
                onSelect={(selected) => onFieldChange(formItemProps[formItemPropsName].name, selected)}
              />
            </div>
          </Form.Item>
        );
      case 'videoConfig':
        return (
          <Form.Item key={'videoConfig'} name={'videoConfig'} label={formItemProps[formItemPropsName].label}>
            <VideoConfig
              initialState={initialValues.videoConfig}
              categories={videoCategoriesList}
              multiple
              isActive
              className={'ml-6 text-base'}
              onChange={(selected) => onFieldChange('videoConfig', selected)}
            />
          </Form.Item>
        );
      case 'uploadFile':
        return (
          <Form.Item
            label={'File'}
            name={formItemProps[formItemPropsName].name}
            rules={[
              {
                validator: () => {
                  if (updatedFormValues[formItemProps[formItemPropsName].name] !== undefined) {
                    setFormErrors((state) => ({ ...state, [formItemProps[formItemPropsName].name]: false }));
                    return Promise.resolve();
                  } else {
                    setFormErrors((state) => ({ ...state, [formItemProps[formItemPropsName].name]: true }));
                    return Promise.reject('err');
                  }
                },

                message: 'Please upload a file!'
              }
            ]}
            key={formItemPropsName}>
            <Upload
              className={classNames(
                'p-4 bg-neutral-100',
                formErrors && formErrors[formItemProps[formItemPropsName].name] && 'border-custom-red border-[1px]'
              )}
              onSelectVideo={(data) => onFieldChange(formItemProps[formItemPropsName].name, data)}
            />
          </Form.Item>
        );

      case 'dateofbirth':
        return (
          <Form.Item
            label={'Date of birth'}
            name={formItemProps[formItemPropsName].name}
            rules={[
              {
                required: true,
                message: 'Please select date of birth!'
              },
              {
                validator: (_, value) =>
                  dayjs().get('year') - value.get('year') > gymSettings.minimalUserAge
                    ? Promise.resolve()
                    : dayjs().get('year') - value.get('year') === gymSettings.minimalUserAge &&
                        dayjs().format('DD/MM') >= value.format('DD/MM')
                      ? Promise.resolve()
                      : Promise.reject('err'),
                message: 'Unfortunately you are to young to sign up'
              }
            ]}
            key={formItemPropsName}>
            <DatePicker
              placeholder={formItemProps[formItemPropsName].placeholder}
              className={'w-full'}
              format={'YYYY-MM-DD'}
              disabledDate={(current) => current && current > dayjs().endOf('day')}
            />
          </Form.Item>
        );
      case 'waitList':
      case 'waitListConfig': {
        return (
          <>
            <Form.Item name={['waitListConfig', 'active']} valuePropName={'checked'}>
              <Checkbox>Show wait list?</Checkbox>
            </Form.Item>

            {updatedFormValues.waitListConfig !== undefined && updatedFormValues.waitListConfig.active && (
              <>
                <Form.Item
                  className={'ml-8'}
                  name={['waitListConfig', 'minimalTimeBeforeClassStartsToNotifyUsers']}
                  label="Cut off before the start time to tell waiting list there is a space (minutes)"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter value!'
                    }
                  ]}>
                  <InputNumber min="0" className={'w-full'} defaultValue={2} />
                </Form.Item>
                <Form.Item
                  className={'ml-8'}
                  name={['waitListConfig', 'timeDelayForSendingNotifications']}
                  label={'Minutes to delay sending notifications'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter value!'
                    }
                  ]}>
                  <InputNumber min="0" className={'w-full'} defaultValue={2} />
                </Form.Item>
              </>
            )}
          </>
        );
      }
      case 'selectPTClients': {
        const classesDetails = returnClassesDetails(classType);
        const selectedClientData =
          updatedFormValues.selectedClientId !== undefined
            ? usersList.find((elm) => elm._id === updatedFormValues.selectedClientId)
            : undefined;
        const selectedClientPassData =
          updatedFormValues.selectedClientPassId !== undefined
            ? selectedClientData[classesDetails.usersClassesLeft][updatedFormValues.selectedClientPassId]
            : undefined;

        const filteredUsers =
          updatedFormValues.selectedClientId !== undefined && updatedFormValues.selectedClientPassId !== undefined
            ? usersList.filter(
                (elm) =>
                  elm._id !== updatedFormValues.selectedClientId &&
                  elm[classesDetails.usersClassesLeft] &&
                  elm[classesDetails.usersClassesLeft].length > 0 &&
                  elm[classesDetails.usersClassesLeft].filter(
                    (pass) =>
                      pass.places === selectedClientPassData.places &&
                      pass.duration === selectedClientPassData.duration &&
                      pass.classesLeft > 0
                  ).length > 0
              )
            : [];

        return (
          <>
            <Form.Item
              label={'Select client'}
              name={'selectedClientId'}
              rules={[
                {
                  required: true,
                  message: 'Please select at least one client!'
                }
              ]}>
              <Select
                placeholder="Select client"
                showSearch
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                options={usersList
                  .filter(
                    (elm) => elm[classesDetails.usersClassesLeft] && elm[classesDetails.usersClassesLeft].length > 0
                  )
                  .map((elm) => ({
                    value: elm._id,
                    label: `${elm.name} ${elm.last_name}`,
                    key: 'selectedClient' + elm._id
                  }))}
              />
            </Form.Item>
            {updatedFormValues.selectedClientId !== undefined && (
              <Form.Item
                className={'ml-6'}
                label={"Select client's pass"}
                name={'selectedClientPassId'}
                rules={[
                  {
                    required: true,
                    message: 'Please select at least one client!'
                  }
                ]}>
                <Select
                  placeholder="Select client"
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  options={selectedClientData.PTclassesLeft.map((elm, i) => ({
                    value: i,
                    label: `${elm.duration}mins - ${elm.places} places ( ${elm.classesLeft} left)`,
                    key: 'pass' + i
                  }))}
                />
              </Form.Item>
            )}
            {updatedFormValues.selectedClientPassId !== undefined &&
              updatedFormValues.selectedClientId !== undefined &&
              selectedClientPassData?.places > 1 && (
                <Form.Item
                  className={'ml-6'}
                  label={'Additional clients'}
                  name={'additionalClientsId'}
                  rules={[
                    {
                      required: true,
                      message: 'Please select client!'
                    }
                  ]}>
                  <Select
                    open={
                      updatedFormValues.additionalClientsId !== undefined
                        ? selectedClientPassData.places > updatedFormValues.additionalClientsId.length + 1
                        : true
                    }
                    mode={selectedClientPassData?.places > 1 ? 'multiple' : ''}
                    placeholder="Select additional clients"
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={
                      filteredUsers.length > 0
                        ? filteredUsers.map((elm) => ({
                            value: elm._id,
                            label: `${elm.name} ${elm.last_name}`,
                            key: 'additionalClients' + elm._id
                          }))
                        : []
                    }
                  />
                </Form.Item>
              )}
          </>
        );
      }
      case 'shifts':
        return (
          <Form.List name={'shifts'} initialValue={updatedFormValues.shifts} key={formItemPropsName}>
            {(fields, { add, remove }) => (
              <>
                <div className={'formLabel'}>Shifts</div>
                {fields.length === 0 ? (
                  <Button label={'add shift'} onClick={add} fullWidth />
                ) : (
                  <div className="grid grid-cols-[1fr_1fr_1fr_80px] gap-x-4">
                    <div>Day</div>
                    <div>Starts</div>
                    <div>Ends</div>
                  </div>
                )}
                {fields.length > 0
                  ? fields.map(({ key, name, ...restField }) => (
                      <div key={key} className="grid grid-cols-[1fr_1fr_1fr_80px] gap-x-3">
                        <Form.Item
                          {...restField}
                          name={[name, 'day']}
                          rules={[
                            {
                              required: true,
                              message: 'Please select day!'
                            }
                          ]}>
                          <Select placeholder={'Select day'} options={daysOfWeek} className="w-full h-[32px]" />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'startingFrom']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter time!'
                            },
                            {
                              validator: () => {
                                const isValid = dayjs(form.getFieldsValue().shifts[key].startingFrom, 'HH:mm').isBefore(
                                  dayjs(form.getFieldsValue().shifts[key].endsAt, 'HH:mm')
                                );

                                setFormErrors((state) => ({
                                  ...state,
                                  [`shifts.${key}.startingFrom`]: !isValid,
                                  [`shifts.${key}.endsAt`]: !isValid
                                }));

                                form.setFields([
                                  {
                                    name: ['shifts', key, 'startingFrom'],
                                    errors: isValid ? [] : ['Start time should be before end time!']
                                  },
                                  {
                                    name: ['shifts', key, 'endsAt'],
                                    errors: isValid ? [] : ['End time should be after start time!']
                                  }
                                ]);
                              },
                              message: 'Start time should be before end time!'
                            }
                          ]}>
                          <TimePicker
                            allowClear={false}
                            format={'HH:mm'}
                            className="w-full h-[32px]"
                            placeholder={'Select time'}
                            status={formErrors[`shifts.${key}.startingFrom`] && 'error'}
                          />
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'endsAt']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter time!'
                            },
                            {
                              validator: () => {
                                const isValid = dayjs(form.getFieldsValue().shifts[key].startingFrom, 'HH:mm').isBefore(
                                  dayjs(form.getFieldsValue().shifts[key].endsAt, 'HH:mm')
                                );
                                setFormErrors((state) => ({
                                  ...state,
                                  [`shifts.${key}.startingFrom`]: !isValid,
                                  [`shifts.${key}.endsAt`]: !isValid
                                }));
                                form.setFields([
                                  {
                                    name: ['shifts', key, 'startingFrom'],
                                    errors: isValid ? [] : ['Start time should be before end time!']
                                  },
                                  {
                                    name: ['shifts', key, 'endsAt'],
                                    errors: isValid ? [] : ['End time should be after start time!']
                                  }
                                ]);
                              },
                              message: 'Start time should be before end time!'
                            }
                          ]}>
                          <TimePicker
                            allowClear={false}
                            format={'HH:mm'}
                            className="w-full h-[32px]"
                            placeholder={'Select time'}
                            status={formErrors[`shifts.${key}.endsAt`] && 'error'}
                          />
                        </Form.Item>

                        <div className={'flex mb-[10px]'}>
                          <Icon name={'add'} onClick={add} />
                          <Icon name={'delete'} onClick={() => remove(name)} />
                        </div>
                      </div>
                    ))
                  : null}
              </>
            )}
          </Form.List>
        );
      case 'scheduledDaysOfTheWeek':
        //TODO: nicer validation handler
        return daysOfWeek.map((elm) => (
          <Form.Item
            label={elm.label}
            key={elm.value}
            name={elm.value}
            rules={[
              {
                validator: () => {
                  return areScheduleDaysValid(form.getFieldsValue()) ? Promise.resolve() : Promise.reject('err');
                },
                message: 'Please enter at least one time in a day!'
              },
              {
                validator: (field, value) => {
                  return isValueCorrect(value)
                    ? isValidScheduleDay(value, false)
                      ? Promise.resolve()
                      : Promise.reject('err')
                    : Promise.resolve();
                },
                message: 'Please enter a date in "h:mm a" format! (e.g. 1:00 AM, 1:00 PM)'
              }
              /* {
                validator: (field, value) => {
                  const areAllFieldsValid = areScheduleDaysValid(form.getFieldsValue());
                  const isFieldValid = isValidScheduleDay(value, false);
                  const errorsResult = daysOfWeek.map((element) => ({
                    name: [element.value],
                    errors: !areAllFieldsValid
                      ? ['Please enter at least one time in a day!']
                      : !isFieldValid && element.value === field.field
                        ? ['Please enter a date in "h:mm a" format! (e.g. 1:00 AM, 1:00 PM)']
                        : [],
                    warnings: []
                  }));

                  setFormErrors((state) => ({
                    ...state,
                    ...daysOfWeek.reduce(
                      (a, v) => ({
                        ...a,
                        [v.value]: !areAllFieldsValid || (!isFieldValid && v.value === field.fullField)
                      }),
                      {}
                    )
                  }));
                  form.setFields(errorsResult);
                },
                message: 'Please enter at least one time in a day!'
              }*/
            ]}>
            <Input placeholder={'Enter time'} status={formErrors[elm.value] && 'error'} />
          </Form.Item>
        ));

      case 'selectGymsAccess':
        return (
          <div key={formItemPropsName}>
            <Form.Item label={"Select gym's access"} name={'gymsFullAccess'}>
              <Checkbox
                defaultChecked={updatedFormValues?.gymsFullAccess}
                onChange={(e) => onFieldChange('gymsFullAccess', e.target.checked)}>
                Full access
              </Checkbox>
            </Form.Item>

            {updatedFormValues !== undefined && !updatedFormValues.gymsFullAccess && (
              <Form.Item
                label={null}
                name={'gymId'}
                rules={[
                  {
                    required:
                      updatedFormValues.gymsFullAccess === false || updatedFormValues.gymsFullAccess === undefined,
                    message: 'Please select at least one gym!'
                  }
                ]}>
                <Select
                  mode="multiple"
                  placeholder="Select gym access"
                  showSearch
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  options={gymsList.map((elm) => ({ value: elm._id, label: elm.name, key: elm._id }))}
                />
              </Form.Item>
            )}
          </div>
        );
      default:
        return (
          <Form.Item
            {...formItemProps[formItemPropsName]}
            key={formItemPropsName}
            valuePropName={formItemProps[formItemPropsName].isCheckbox ? 'checked' : 'value'}>
            {returnItem(formItemPropsName, formItemProps[formItemPropsName])}
          </Form.Item>
        );
    }
  };

  const saveBtn = (
    <ModalButtonsContainer isLoading={btnIsLoading}>
      <Button secondary htmlType="submit" label={btnLabel} fullWidth />
      {cancelBtn && <Button primary label="cancel" fullWidth onClick={onCancel} />}
    </ModalButtonsContainer>
  );

  return (
    <Form
      onValuesChange={() => onFieldChange('', '')}
      initialValues={initialValues}
      scrollToFirstError={true}
      form={form}
      layout="vertical"
      onFinish={handleOnFinish}
      onFinishFailed={onFinishFailed}
      autoComplete={autoComplete}
      className={className}>
      {allFormFields.map((elm) => returnFormField(elm))}
      {saveBtn}
    </Form>
  );
};

export default RenderForm;
